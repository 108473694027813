<template>
  <v-app light id="app">

    <v-app-bar
      elevation="2"
      app
      color="secondary"
      clipped-left
    >

      <Toolbar>
        <template v-slot:prepend>
          <v-btn
              tile
              dark
              color="secondary"
              elevation="0"
              class="mr-0 white--text"
              @click="$router.push({ name: 'projects-overview' })"
              height="64"
          >
            <v-icon>
              mdi-folder-multiple
            </v-icon>
          </v-btn>
        </template>
      </Toolbar>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      permanent
      color="white"
      dark
      class="bg-gradient-primary"
      elevation="0"

      clipped
    >

      <Drawer />

    </v-navigation-drawer>

    <v-main>
      <v-container
        fluid
        fill-height
        class="pa-0 align-start"
      >
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Drawer from '@/components/Layout/Drawer';
import Toolbar from '@/components/Layout/Toolbar';

export default {
  name: 'AppLogged',
  components: {
    Drawer,
    Toolbar,
  },
  data() {
    return {
      drawer: false,
      mini: false,
    };
  },
  async mounted() {
    try {
      await this.$store.dispatch('LOG');
    } catch (error) {
      // this.$router.push("/login");
    }
  },
};
</script>
<style lang="scss" scoped>
.bg-gradient-primary {
  background-image: linear-gradient(90deg, #416C78, #345761) !important;
}
</style>
