const state = {
  fields: [],
};
const getters = {};

const actions = {
  LOAD_BIEN(context) {
    return this.$axios
      .get(`/api/bien/${context.rootState.app.selectedProject.id}`)
      .then((r) => r.data);
  },
  SAVE_BIEN(context, bien) {
    return this.$axios.post(`/api/bien/${bien.id}/update`, bien)
      .then((result) => result.data);
  },
};

const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
