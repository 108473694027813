import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return new Promise((resolve, reject) => {
    originalPush.call(this, location, () => {
      resolve(this.currentRoute);
    }, (error) => {
      // on abort

      // only ignore NavigationDuplicated error
      if (error.name === 'NavigationDuplicated') {
        resolve(this.currentRoute);
      } else {
        reject(error);
      }
    });
  });
};

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/Login'),
    },
    {
      name: 'continue_with_google',
      path: '/continue-with-google',
      component: () => import('@/views/ContinueWithGoogle'),
    },
    {
      name: 'forgot_your_password',
      path: '/forgot-your-password',
      component: () => import('@/views/ForgotYourPassword'),
    },
    {
      name: 'reset_your_password/:token',
      path: '/reset-your-password/:token',
      component: () => import('@/views/ResetYourPassword'),
    },
    {
      name: 'change-organization',
      path: '/change-organization',
      component: () => import('@/views/ChangeOrganization'),
    },
    {
      name: 'projects-overview',
      path: '/projects',
      component: () => import('@/views/MetaProject'),
      children: [
        {
          path: ':id',
          component: () => import('@/components/MetaProject/MetaProjectView'),
        },
      ],
    },
    {
      path: '/activites',
      component: () => import('@/views/Activite'),
      children: [
        {
          path: '',
          component: () => import('@/components/Activite/ActiviteMain'),
          children: [
            {
              path: 'node/:id/team',
              component: () => import('@/components/Activite/TeamEdit'),
              props: true,
            },
          ],
        },
        {
          path: 'draft',
          component: () => import('@/components/Activite/ActiviteDraft'),
          children: [
            {
              path: 'create',
              component: () => import('@/components/Activite/Create'),
              props: true,
            },
            {
              path: 'edit',
              component: () => import('@/components/Activite/Edit'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: '/dashboard',
      component: require('@/views/Dashboard').default,
    },
    {
      path: '/documentation',
      component: require('@/views/Documentation').default,
    },
    {
      path: '/socio_eco',
      component: require('@/views/SocioEco').default,
    },
    {
      path: '/pp',
      component: () => import('@/views/PartiePrenante'),
      children: [
        {
          path: '',
          name: 'pp',
          component: () => import('@/components/PartiePrenante/Liste'),
        },
        {
          name: 'pp-id',
          path: ':id',
          component: () => import('@/components/PartiePrenante/Pp'),
          children: [
            {
              path: 'upload',
              name: 'add-pp-file',
              component: () => import('@/components/PartiePrenante/AddFile'),
            },
            {
              path: 'add-receipt',
              name: 'add-receipt',
              component: () => import('@/components/PartiePrenante/Fdb/AddReceipt'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: '/contrat/:id',
      component: () => import('@/views/EditContrat'),
    },
    {
      path: '/compensation',
      component: () => import('@/views/Compensation'),
      children: [
        {
          path: 'liste',
          component: () => import('@/components/Compensation/Liste'),
        },
        {
          path: 'prev',
          component: () => import('@/components/Compensation/Budget'),
        },
        {
          path: 'matrix',
          component: () => import('@/components/Compensation/PriceMatrix'),
        },
      ],
    },
    {
      path: '/report/:type',
      component: () => import('@/views/Report'),
      children: [
        {
          path: '/',
          component: () => import('@/components/Report/ReportListe'),
          props: true,
          children: [
            {
              path: 'create',
              name: 'create-report',
              component: () => import('@/components/Report/Create'),
              props: true,
            },
            {
              path: 'import',
              name: 'import-reports',
              component: () => import('@/components/Report/ImportReports'),
              props: true,
            },
          ],
        },
        {
          path: 'edit/:id',
          name: 'edit-report',
          component: () => import('@/components/Report/EditReport'),
          props: true,
          children: [
            {
              path: 'add_state',
              name: 'add-state',
              component: () => import('@/components/Report/ChangeState'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: '/engagment',
      component: () => import('@/views/Engagment'),
      children: [
        {
          path: '/',
          component: () => import('@/components/Engagment/EngagmentListe'),
          props: true,
          children: [
            {
              path: 'create',
              name: 'create-engagment',
              component: () => import('@/components/Engagment/EngagmentCreate'),
              props: true,
            },
          ],
        },
        {
          path: 'edit',
          name: 'edit-engagment',
          component: () => import('@/components/Engagment/EngagmentEdit'),
          props: true,
        },
      ],
    },
    {
      path: '/budget',
      component: () => import('@/views/Budget'),
      children: [
        {
          path: '/',
          component: () => import('@/components/Budget/BudgetListe'),
          children: [
            {
              path: 'create',
              name: 'budget-create',
              component: () => import('@/components/Budget/BudgetCreate'),
            },
            {
              path: 'add-paiement',
              name: 'budget-add-paiement',
              component: () => import('@/components/Budget/BudgetAddPaiementDialog'),
              props: true,
            },
          ],
        },
      ],
    },
    {
      path: '/paiement',
      component: () => import('@/views/Paiement'),
      children: [
        {
          path: '',
          component: () => import('@/components/Paiements/Liste'),
        },
      ],
    },
    {
      path: '/parcelle',
      component: () => import('@/views/Parcelle'),
    },
    {
      path: '/admin',
      component: () => import('@/views/Administration'),
      children: [
        {
          path: 'organisation',
          component: () => import('@/components/Administration/Organisation'),
          children: [
            {
              path: 'user/create',
              component: () => import('@/components/User/CreateUser'),
            },
            {
              path: 'user/edit/:id',
              component: () => import('@/components/User/CreateUser'),
              props: true,
            },
            {
              path: 'mods/project/:id',
              component: () => import('@/components/Mods/EditForProject'),
              props: true,
            },
            {
              path: 'mods/user/:id',
              component: () => import('@/components/Mods/EditForUser'),
              props: true,
            },
          ],
        },
        {
          path: 'pp',
          component: () => import('@/components/Administration/PartiePrenante'),
          children: [
            {
              path: 'create',
              name: 'report-type-create',
              component: () => import('@/components/Administration/Dialog/CreateReportType'),
            },
          ],
        },
        {
          path: 'donnees',
          component: () => import('@/components/Administration/Donnees'),
          children: [
            {
              path: 'source/create',
              component: () => import('@/components/Administration/Dialog/CreateSource'),
            },
            {
              path: 'socioeco/import',
              component: () => import('@/components/Administration/Donnees/ImportSEParam'),
            },
          ],
        },
        {
          path: 'reinstallation',
          component: () => import('@/components/Administration/Reinstallation'),
        },
        {
          path: 'monitoring',
          component: () => import('@/components/Administration/Monitoring'),
        },
      ],
    },
    {
      path: '/sandbox',
      component: () => import('@/views/Sandbox'),
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: () => {
        store.commit('LOGOUT');
        router.push({ name: 'login' });
      },
    },
    {
      path: '/haiti',
      name: 'haiti',
      component: () => import('@/views/Haiti'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  // if (to.fullPath === from.fullPath) return;
  if (to.path === '/') {
    next({
      path: '/login',
    });
  }
  return next();
});
export default router;
