<template>
  <div class="d-flex col-12 ma-0 pa-0 align-center" id="toolbar">
    <a class="mx-6" href="/">
      <img
        style="height: 45px"
        :src="
          user ? user.organization.logo_url : '/assets/img/sycosur_logo_white.png'
        "
      />
    </a>
    <div class="ml-10">
      <h1 class="white--text text-h5 d-flex justify-start align-center">
      <portal-target name="toolbarTitle"></portal-target>
      <v-icon v-if="displaySubTitle" color="white" class="px-2">mdi-slash-forward</v-icon>
      <portal-target name="toolbarSubTitle" @change="handleSubTitlePortal"></portal-target>
      </h1>
    </div>
    <v-spacer />
    <slot name="prepend"/>
    <v-btn
        v-if="isSuperAdmin"
        tile
        dark
        color="secondary"
        elevation="0"
        class="mr-0 white--text"
        @click="$router.push({name: 'change-organization'})"
        height="64"
    >
      <v-icon>
        mdi-swap-horizontal
      </v-icon>
    </v-btn>
    <v-menu open-on-hover offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          tile
          dark
          color="secondary"
          elevation="0"
          class="mr-0 white--text"
          height="64"
        >
          <v-icon left class="white--text">mdi-web</v-icon>
        </v-btn>
      </template>
      <v-list :disabled="false">
        <v-list-item-group
            @change="updateLanguage"
        >
          <v-list-item
              v-for="(item, i) in languages"
              :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <v-btn
    tile
    dark
    color="accent"
    elevation="0"
    class="mr-0 white--text"
    height="64"
    disabled
    >
      <v-icon left class="white--text">mdi-account</v-icon>
      <span class="white--text">{{ user ? user.name : '' }}</span>
    </v-btn>
    <add-usercomment/>
    <v-btn
      tile
      dark
      color="info"
      elevation="0"
      class="mr-0 white--text"
      @click="$router.push({name: 'logout'})"
      height="64"
    >
      <v-icon>
        mdi-power
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AddUsercomment from '@/components/User/AddUsercomment';
import moment from 'moment';
import numeral from 'numeral';

export default {
  name: 'Toolbar',
  components: { AddUsercomment },
  data: () => ({
    displaySubTitle: false,
    languages: [
      { id: 'fr', text: 'menu.locale_francais' },
      { id: 'en', text: 'menu.locale_anglais' },
      { id: 'es', text: 'menu.locale_espagnol' },
    ],
    inset: false,
    menuItem: '',
  }),
  watch: {
    $route() {
      this.menuItem = this.$route.path;
    },
  },
  mounted() {
    this.menuItem = this.$route.path;
  },
  computed: {
    ...mapState({
      user: (state) => state.app.user,
      menuList: (state) => state.app.menu,
      projects: (state) => state.app.projects,
      selectedProject: (state) => state.app.selectedProject,
      report_types: (state) => state.report.report_types,
    }),
    ...mapGetters({
      isSuperAdmin: 'isSuperAdmin',
      formulaires: 'formulaire/formulairesByProjectMenu',
    }),
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    handleSubTitlePortal(value) {
      this.displaySubTitle = value;
    },
    updateLanguage(index) {
      this.user.locale = this.languages[index].id;
      this.$store.dispatch('user/UPDATE_USER', this.user)
        .then(() => {
          this.$i18n.locale = this.languages[index].id;
          moment.locale(this.languages[index].id);
          numeral.locale(this.languages[index].id);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--dark.v-btn.v-btn--disabled .v-icon.white--text {
  color:#ffffff !important;
}
</style>
