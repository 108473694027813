export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

export function expireIn() {
  const token = localStorage.getItem('id_token');
  let delay = 1;
  if (token) {
    const tokenValues = parseJwt(token);
    delay = Math.max(1000, (tokenValues.exp * 1000) - Date.now());
  }
  return delay / 1000;
}
