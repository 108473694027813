<template>
  <v-app :style="cssProps">
    <v-overlay :value="loading > 0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <router-view></router-view>
    <message></message>
    <overlay-batch/>
  </v-app>
</template>

<script>
import Message from '@/components/Message';
import { mapState } from 'vuex';
import OverlayBatch from '@/components/Generics/OverlayBatch';

export default {
  components: {
    OverlayBatch,
    Message,
  },
  computed: {
    ...mapState('loader', ['loading']),
    cssProps() {
      const themeColors = {};
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
      });
      return themeColors;
    },
  },
};
</script>

<style lang="scss">
@import "styles/app";
html { overflow-y: auto !important;}
</style>
